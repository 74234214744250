import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const TEACHER = 'Teacher';
const ASSISTANT = 'Assistant';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            lectureFilter: hook((ctx) => {
              const lectures = ctx.get('@item.course_room_teachers');
              return {
                teachers: React.useMemo(
                  () => _.filter(lectures, (item) => _.get(item, 'teacher_type') === TEACHER),
                  []
                ),
                assistants: React.useMemo(
                  () => _.filter(lectures, (item) => _.get(item, 'teacher_type') === ASSISTANT),
                  []
                ),
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
