import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import moment from 'moment';
import cx from 'classnames';
import { momentLocalizer } from 'react-big-calendar';
import i18n from 'i18n-js';
import { TIME_HOUR_MINUTE_ML_FORMAT, START_DATE_ML_FORMAT } from '@vl/mod-utils/datetime';

export const CustomMonthEvent = ({ event }) => {
  const localizer = momentLocalizer(moment);
  const textColor = cx('text-white', {
    'text-brandb500': moment().isAfter(event.end),
    'text-yellow500': moment().isBetween(event.start, event.end),
    'text-blue500': moment().isBefore(event.start),
  });
  const titleEllipsis = 'text-ellipsis-1';

  return (
    <DIV>
      <div className={cx('flex justify-between h-full', textColor)}>
        <div className={cx('font-semibold text-main text-xs', titleEllipsis)}>
          {moment(event.start).format(_.get(TIME_HOUR_MINUTE_ML_FORMAT, i18n.locale, TIME_HOUR_MINUTE_ML_FORMAT.de))}:{' '}
          <div className="text-main">{event.title}</div>
        </div>
      </div>
    </DIV>
  );
};

export default CustomMonthEvent;
