import React from 'react';

import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';

import _ from 'lodash';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import useRoute from '@vl/hooks/useGbRouteDe';
import moment from 'moment';

import RenderError from './RenderError';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const route = useRoute();
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const userId = ctx.apply('authModel.getUserId');
              const item = ctx.get('@item');
              const room = _.get(item, 'room');
              const form = useFormik({
                enableReinitialize: false,
                initialValues: {
                  user_id: userId,
                  room_id: _.get(room, 'id'),
                  lesson_name: _.get(room, 'name', ''),
                  course_name: _.get(room, 'course.name', ''),
                  start_at: _.get(item, 'start_at'),
                  reason: '',
                  session_duration: moment(_.get(room, 'end_at')).diff(moment(_.get(room, 'start_at')), 'seconds'),
                },
                onSubmit: async (values, actions) => {
                  const errors = {};
                  const params = {
                    ...values,
                  };

                  try {
                    // const data = await fbFnsClient.getClient().post('course-rescheduleRoom', params);
                    // if (_.get(data, 'errors')) {
                    //   errors.sessions = RenderError(data.errors);
                    //   return form.setErrors({ sessions: RenderError(data.errors) });
                    // }
                    // if (_.get(data, 'error')) {
                    //   return actions.setStatus({
                    //     error: data,
                    //   });
                    // }
                    // form.handleCancel();
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },
              });

              _.assign(form, {
                isModalVisible,
                canSubmit: !_.get(form, 'isSubmitting') && _.get(form, 'isValid') && _.get(form, 'dirty'),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },

                handleChangeCheckbox: (value) => {
                  const { checked, name } = value.event.target;

                  const field = _.get(value, 'field', '');
                  const rec = _.get(value, 'value', '');

                  if (checked) {
                    form.setFieldValue(field, [..._.get(form.values, field), { ...rec }]);
                  } else {
                    form.setFieldValue(
                      field,
                      _.filter(_.get(form, `values.${field}`), (v) => {
                        return _.get(v, 'id') !== name;
                      })
                    );
                  }
                },
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
