import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import { Modal, Alert, Row, Col, Input } from 'antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import '../modal.css';
import { Form, FormItem, DatePicker, Radio } from 'formik-antd';
import Button from '@uz/unitz-components-web/Button';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import CustomRadio from '@uz/unitz-components-web/CustomRadio';
import InputText from '@uz/unitz-components-web/InputText';
import CustomTable from '@uz/unitz-components-web/CustomTable';
import AddAssistantModal from '@uz/unitz-tool-components/AddTeacherToCourseModal';
import AddTeacherModal from '@uz/unitz-tool-components/AddAssistantToCourseModal';
import i18n from 'i18n-js';
import { START_DATE_YEAR_ML_FORMAT, TIME_HOUR_MINUTE_ML_FORMAT } from '@vl/mod-utils/datetime';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
  labelAlign: 'left',
};

const gutter = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
};

const SESSION_START_TIME_FORMAT = 'HH:mm';

export default function CourseRoomManageSubstitutesModal() {
  const [isTeacherVisible, $isTeacherVisible] = React.useState(false);
  const [isAssistantVisible, $isAssistantVisible] = React.useState(false);

  const locale = i18n.locale;

  const openModal = () => {
    $isTeacherVisible(true);
  };

  const hideModal = () => {
    $isTeacherVisible(false);
  };
  const openModalAssistant = () => {
    $isAssistantVisible(true);
  };

  const hideModalAssistant = () => {
    $isAssistantVisible(false);
  };

  return (
    <DIV className="component">
      <Modal
        title={
          <div className="py-2 text-xl text-main font-semibold">
            {ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.manageSubstitutes')}
          </div>
        }
        style={{ zIndex: !!ctx.get('form.isModalVisible') ? 1035 : 0 }}
        wrapClassName="lesson-modal"
        visible={ctx.get('form.isModalVisible')}
        destroyOnClose
        footer={null}
        width={688}
        closeIcon={<div className="mt-4">{gstyles.icons({ name: 'close', size: 24, fill: gstyles.colors.sub })}</div>}
        maskStyle={{ background: gstyles.colors.black300 }}
        onCancel={ctx.get('form.handleCancel')}
        afterClose={() => ctx.apply('modalModel.hide')}
      >
        <>
          {ctx.get('form.isModalVisible') &&
            (() => {
              const dateFormatter = (value) => {
                const session_duration = ctx.get('form.values.session_duration') || 60 * 60;
                const endValue = value.clone().add(session_duration, 'second');

                return `${value.format(
                  `${_.get(START_DATE_YEAR_ML_FORMAT, locale, 'de')} ${_.get(TIME_HOUR_MINUTE_ML_FORMAT, locale, 'de')}`
                )} - ${endValue.format(_.get(TIME_HOUR_MINUTE_ML_FORMAT, locale, 'de'))}`;
              };

              return (
                <FormProvider form={ctx.get('form')}>
                  <Form className="space-y-4" {...layout}>
                    {ctx.get('form.status.error') && (
                      <div className="py-2">
                        <Alert message={ctx.get('form.status.error.message')} type="error" />
                      </div>
                    )}
                    {!!ctx.get('form.errors.sessions') &&
                      (_.isString(ctx.get('form.errors.sessions')) ||
                        React.isValidElement(ctx.get('form.errors.sessions'))) && (
                        <div className="py-2">
                          <Alert message={ctx.get('form.errors.sessions')} type="error" showIcon />
                        </div>
                      )}

                    <CustomInput>
                      <FormItem
                        name={'lesson_name'}
                        label={
                          <span className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.modals.form.lessonName')}
                          </span>
                        }
                      >
                        <InputText name={'lesson_name'} disabled />
                      </FormItem>
                    </CustomInput>

                    <CustomInput>
                      <FormItem
                        name={'course_name'}
                        label={
                          <span className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.modals.form.lessonName')}
                          </span>
                        }
                      >
                        <InputText name={'course_name'} disabled />
                      </FormItem>
                    </CustomInput>

                    <CustomInput>
                      <FormItem
                        name={'start_at'}
                        label={
                          <span className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'CourseRoom.modals.reschedule.currentTimeFrameLabel')}
                          </span>
                        }
                      >
                        <DatePicker
                          size="medium"
                          showTime={{
                            hourStep: 1,
                            minuteStep: 15,
                            format: SESSION_START_TIME_FORMAT,
                          }}
                          suffixIcon={gstyles.icons({
                            name: 'calendar',
                            size: 16,
                            fill: gstyles.colors.sub,
                          })}
                          format={dateFormatter}
                          name={'start_at'}
                          className="w-full"
                          disabled
                        />
                      </FormItem>
                    </CustomInput>
                    <CustomRadio>
                      <FormItem
                        name="applyFor"
                        label={
                          <div className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.modals.form.applyFor')}
                          </div>
                        }
                      >
                        <Radio.Group name="applyFor">
                          <div className="flex items-center">
                            {_.map(ctx.get('form.selectOptions.sizeEffect'), (item, index) => (
                              <>
                                <Radio key={index} value={item.id}>
                                  <span className="text-sm text-sub font-semibold">{item.text}</span>
                                </Radio>
                              </>
                            ))}
                          </div>
                        </Radio.Group>
                      </FormItem>
                    </CustomRadio>
                    <CustomInput>
                      <FormItem
                        name={'reason'}
                        label={
                          <span className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.modals.form.reason')}
                          </span>
                        }
                      >
                        <Input.TextArea rows={3} name={'reason'} />
                      </FormItem>
                    </CustomInput>

                    <div>
                      <div className="text-sm font-semibold text-sub">
                        {ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.modals.form.manageTeachers')}
                      </div>
                    </div>

                    <CustomTable
                      columns={ctx.get('tableColumn.columns')}
                      dataSource={ctx.get('tableColumn.dataSource', [])}
                      rowKey={(rec) => _.get(rec, 'id')}
                      pagination={false}
                    />

                    <Row justify="end" className="mb-6">
                      <Col span={18}>
                        <Row align="middle" gutter={gutter} justify="end">
                          <Col className="mx-auto" span={12}>
                            <Button
                              type="outline"
                              className="mx-auto"
                              size="small"
                              block
                              icon={gstyles.icons({
                                name: 'add-circle',
                                size: 20,
                                fill: gstyles.colors.brandb500,
                                className: 'inline-block mx-2',
                              })}
                              onClick={openModal}
                            >
                              {ctx.apply('i18n.t', 'ZoomToolCourse.Action.add_teacher')}
                            </Button>
                            <Modal
                              width={1248}
                              footer={null}
                              className="rounded-lg overflow-hidden shadow-md"
                              maskStyle={{ background: gstyles.colors.black300 }}
                              visible={isTeacherVisible}
                              centered
                              bodyStyle={{ paddingRight: 10, paddingLeft: 10 }}
                              wrapClassName={'add-teacher-modal'}
                              onCancel={hideModal}
                              closeIcon={
                                <div className="mt-4">
                                  {gstyles.icons({ name: 'close', size: 24, fill: gstyles.colors.sub })}
                                </div>
                              }
                            >
                              <AddTeacherModal />
                            </Modal>
                          </Col>
                          {/* <Col className="mx-auto" span={12}>
                            <Button
                              type="outline"
                              name="secondary"
                              className="mx-auto"
                              size="small"
                              block
                              icon={gstyles.icons({
                                name: 'add-circle',
                                size: 20,
                                fill: gstyles.colors.branda500,
                                className: 'inline-block mx-2',
                              })}
                              onClick={openModalAssistant}
                            >
                              {ctx.apply('i18n.t', 'ZoomToolCourse.Action.add_assistant')}
                            </Button>
                            <Modal
                              width={1248}
                              footer={null}
                              className="rounded-lg overflow-hidden shadow-md"
                              maskStyle={{ background: gstyles.colors.black300 }}
                              visible={isAssistantVisible}
                              centered
                              bodyStyle={{ paddingRight: 10, paddingLeft: 10 }}
                              wrapClassName={'add-assistant-modal'}
                              onCancel={hideModalAssistant}
                              closeIcon={
                                <div className="mt-4">
                                  {gstyles.icons({ name: 'close', size: 24, fill: gstyles.colors.sub })}
                                </div>
                              }
                            >
                              <AddAssistantModal />
                            </Modal>
                          </Col> */}
                        </Row>
                      </Col>
                    </Row>

                    <Row className="mt-6" justify="end">
                      <Col span={16}>
                        <Button type="primary" block>
                          {ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.buttons.confirmSubstitutes')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </FormProvider>
              );
            })()}
        </>
      </Modal>
    </DIV>
  );
}
