import React from 'react';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';

const CustomRadio = styled.div`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label > label:after {
    content: '';
  }
  .ant-radio-inner {
    width: 20px;
    height: 20px;
  }
  .ant-radio-inner::after {
    width: 20px;
    height: 20px;
    top: 7px;
    left: 7px;
    border-radius: 0;
    border-radius: 50%;
  }
  .ant-radio-checked {
    .ant-radio-inner::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background: ${gstyles.colors.white500};
      top: 5px;
      left: 5px;
      z-index: 10;
      border-radius: 50%;
    }
  }
`;

export default CustomRadio;
