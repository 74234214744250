import React from 'react';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import _ from 'lodash';

export const ColoredDateCellWrapper = ({ children }) => {
  const innerHTML = _.get(children, '_owner.child.stateNode.firstChild.innerHTML');
  _.set(children, '_owner.child.stateNode.firstChild.innerHTML', CourseFormatter.replaceSACH()(innerHTML));
  return React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'white',
    },
  });
};

export default ColoredDateCellWrapper;
