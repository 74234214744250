import React from 'react';
import createLayout from '@vl/mod-utils/createLayout';

export const Layout = createLayout(({ children }) => {
  return (
    <div className="h-full px-4">
      <div id="layoutcontent">{children}</div>
      <div className="h-full flex flex-col relative">
        <div className="content-header">
          <Layout.RenderPOS name="content-header">
            <div className="flex justify-between items-center pt-2 pb-4">
              <Layout.RenderPOS name="content-header-breadcrum">
                {(ele) => ele && <div className="space-x-2">{ele}</div>}
              </Layout.RenderPOS>
              <Layout.RenderPOS name="content-header-title">
                {(ele) => <div className="text-center">{ele}</div>}
              </Layout.RenderPOS>
              <div className="">
                <Layout.RenderPOS name="content-header-action">
                  {(ele) => ele && <div className="space-x-2">{ele}</div>}
                </Layout.RenderPOS>
              </div>
            </div>
          </Layout.RenderPOS>
        </div>
        <div className="content-row w-full flex-grow overflow-hidden h-fill relative">
          <div className="content-body bg-transparent flex flex-col h-full h-fill" style={{ minHeight: 800 }}>
            <Layout.RenderPOS name="content-body" />
          </div>
        </div>
        {/* <div className="content-footer">
            <Layout.RenderPOS name="content-footer" />
          </div> */}
      </div>
    </div>
  );
});

export default Layout;
