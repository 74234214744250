import React from 'react';
import _ from 'lodash';
import { hook, bindings } from '@vl/redata';
import moment from 'moment';

const mod_role = ['account_holder', 'teacher', 'admin'];

export default bindings({
  listItem: {
    rules: [
      [
        'data',
        {
          data: {
            courseRoomModel: hook((ctx) => {
              const item = ctx.get('@item');
              const userId = ctx.apply('authModel.getUserId');
              const data = ctx.apply('accountModel.userRole');
              const model = {
                isPassedRoom: () => {
                  return moment().isAfter(_.get(item, 'room.end_at'));
                },
                isAttendee: () => {
                  return !!_.find(_.get(item, 'attendees', []), { user_id: userId });
                },
                isMod: () => {
                  const checkRole = !!_.filter(data, (item) => {
                    return _.includes(mod_role, item);
                  });
                  return checkRole;
                },
                can: (action) => {
                  if (action === 'dismiss') {
                    // only for future room and attendees
                    if (model.isPassedRoom()) {
                      return false;
                    }
                  } else if (action === 'substitutes') {
                    // only for future room and attendees
                    if (model.isPassedRoom() || !model.isMod()) {
                      return false;
                    }
                  } else if (action === 'reschedule') {
                    if (model.isPassedRoom() || !model.isMod()) {
                      return false;
                    }
                  } else if (action === 'cancel') {
                    if (model.isPassedRoom()) {
                      return false;
                    }
                  }
                  return true;
                },
                linkTo: () => {
                  const advisor_id = _.get(item, 'room.course.advisor_id');
                  return ctx.apply('routeStore.toUrl', 'meMessageDetail', { id: advisor_id });
                },
              };

              return model;
            }),
          },
        },
      ],
    ],
  },
});
