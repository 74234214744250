import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import moment from 'moment';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import i18n from 'i18n-js';
import 'moment/locale/de';
// import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { Select } from 'antd';
import { TIME_HOUR_MINUTE_ML_FORMAT } from '@vl/mod-utils/datetime';
import CustomSelect from '@uz/unitz-tool-components/ZoomToolCalendarComponent/CustomSelect';
import CalendarStyle from '@uz/unitz-tool-components/ZoomToolCalendarComponent/CalendarStyle';
import CustomWeekEvent from '@uz/unitz-tool-components/ZoomToolCalendarComponent/CustomWeekEvent';
import CustomMonthEvent from '@uz/unitz-tool-components/ZoomToolCalendarComponent/CustomMonthEvent';
import ColoredDateCellWrapper from '@uz/unitz-tool-components/ZoomToolCalendarComponent/ColoredDateCellWrapper';
import CustomDayEvent from '@uz/unitz-tool-components/ZoomToolCalendarComponent/CustomDayEvent';
import eventStyleGetter from '@uz/unitz-tool-components/ZoomToolCalendarComponent/eventStyleGetter';
import CustomEventWrapper from '@uz/unitz-tool-components/ZoomToolCalendarComponent/CustomEventWrapper';

const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);

const timeStep = 15;
const timeSlot = 4;

const Index = () => {
  moment.locale(i18n.locale);
  const [events, $events] = React.useState([]);
  const [draggedEvent, $draggedEvent] = React.useState({});
  const [displayDragItemInCell] = React.useState(null);

  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    let allDay = event.allDay;

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }

    const nextEvents = events.map((existingEvent) => {
      return existingEvent.id === event.id
        ? {
            ...existingEvent,
            start,
            end,
            allDay,
          }
        : existingEvent;
    });

    $events(nextEvents);
  };

  const resizeEvent = ({ event, start, end }) => {
    const nextEvents = events.map((existingEvent) => {
      return existingEvent.id === event.id ? { ...existingEvent, start, end } : existingEvent;
    });
    $events(nextEvents);
  };

  const newEvent = (event) => {};

  const handleDragStart = (event) => {
    $draggedEvent(event);
  };

  const dragFromOutsideItem = () => {
    return draggedEvent;
  };

  const onDropFromOutside = ({ start, end, allDay }) => {
    const event = {
      id: draggedEvent.id,
      title: draggedEvent.title,
      start,
      end,
      allDay,
    };

    moveEvent({ event, start, end });
  };

  return (
    <DIV className="componentContainer widget">
      <div className="flex flex-col h-full overflow-hidden bg-white rounded-lg h-fill-position">
        {/* <CustomSelect className="px-3">
          <Select
            name="course"
            size="large"
            mode="multiple"
            placeholder={ctx.apply('i18n.t', 'Calendar.FilterByCourses')}
            onChange={ctx.get('coursesFilter.filterSet')}
            optionLabelProp="label"
            className="w-full text-black shadow-sm"
            maxTagTextLength={15}
            allowClear
          >
            {ctx.get('coursePagingModel.items', []).map((course) => {
              return (
                <Select.Option value={course.name} key={course.id}>
                  {course.name}
                </Select.Option>
              );
            })}
          </Select>
        </CustomSelect> */}
        <CalendarStyle className="flex-grow">
          <DragAndDropCalendar
            defaultView={Views.WEEK}
            views={{
              day: true,
              week: true,
              month: true,
            }}
            className="pt-4 overflow-hidden"
            selectable
            localizer={localizer}
            events={ctx.get('coursesFilter.filterEvents', [])}
            onEventDrop={moveEvent}
            // resizable={true}
            onEventResize={resizeEvent}
            onSelectSlot={newEvent}
            onDragStart={console.log}
            defaultDate={moment().toDate()}
            dragFromOutsideItem={displayDragItemInCell ? dragFromOutsideItem : null}
            onDropFromOutside={onDropFromOutside}
            handleDragStart={handleDragStart}
            onNavigate={ctx.get('coursesFilter.onNavigate')}
            step={timeStep}
            timeslots={timeSlot}
            components={{
              timeSlotWrapper: ColoredDateCellWrapper,
              day: {
                event: CustomDayEvent,
                eventWrapper: CustomEventWrapper,
              },
              week: {
                header: (event) => {
                  const date = localizer.format(event.date, 'DD');
                  const now = moment().format('ddd DD');

                  return (
                    <div className="flex flex-col w-full justify-center items-center">
                      <div>{localizer.format(event.date, 'ddd')}</div>
                      {now ===
                      moment(event.date)
                        .locale(i18n.locale)
                        .format('ddd DD') ? (
                        <div style={{ padding: '1px 2px' }} className="text-xs text-white500 bg-brandb500 rounded-full">
                          {date}
                        </div>
                      ) : (
                        <div className="bg-transparent text-xs">{date}</div>
                      )}
                    </div>
                  );
                },
                event: CustomWeekEvent,
                eventWrapper: CustomEventWrapper,
              },
              month: {
                event: CustomMonthEvent,
                eventWrapper: CustomEventWrapper,
              },
            }}
            messages={ctx.get('toolbarMessage.messages')}
            eventPropGetter={eventStyleGetter}
            formats={{
              dayFormat: (date, culture, localizer) => {
                const fullDate = localizer.format(date, 'L', culture);
                return `${localizer.format(date, 'ddd ', culture)}${fullDate.slice(3, fullDate.length - 5)}`;
              },
              dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
                if (start.getMonth() === end.getMonth()) {
                  return `${localizer.format(start, 'MMMM', culture)}, ${localizer.format(
                    start,
                    'DD',
                    culture
                  )} - ${localizer.format(end, 'DD', culture)}`;
                }
                return `${localizer.format(start, 'L', culture)} - ${localizer.format(end, 'L', culture)}`;
              },
              monthHeaderFormat: (date, culture, localizer) => {
                return `${localizer.format(date, 'MMMM, YYYY', culture)}`;
              },

              // label of toolbar
              dayHeaderFormat: 'dddd L',
              eventTimeRangeFormat: (event) => {
                return null;
              },
              timeGutterFormat: _.get(TIME_HOUR_MINUTE_ML_FORMAT, i18n.locale, TIME_HOUR_MINUTE_ML_FORMAT.de),
            }}
            dayLayoutAlgorithm="no-overlap"
            // scrollToTime={ctx.get('coursesFilter.initialTime')}
            onView={ctx.get('coursesFilter.viewSet')}
            popup={true}
            showMultiDayTimes
          />
        </CalendarStyle>
      </div>
    </DIV>
  );
};

export default displayName(Index);
