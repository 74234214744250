import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import B2BMemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import _ from 'lodash';

export const View8 = () => {
  return (
    <DIV className="component">
      {!!ctx.get('lectureFilter.teachers.length') && (
        <div className="flex items-center space-x-2 mb-2">
          <div className="text-brandb500 font-semibold text-xs">
            {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.teacher')}:
          </div>
          <div className="text-xs text-brandb500 pl-1">
            {_.map(ctx.get('lectureFilter.teachers'), (teacher, index) =>
              B2BMemberFormatter.fullName(ctx)(_.get(teacher, 'member', ''))
            ).join(', ')}
          </div>
        </div>
      )}

      {!!ctx.get('lectureFilter.assistants.length') && (
        <div className="flex items-center mb-2">
          <div className="text-blue500 font-semibold text-xs">
            {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.assistant')}:
          </div>
          <div className="text-xs text-blue500 pl-1">
            {_.map(ctx.get('lectureFilter.assistants'), (assistant, index) =>
              B2BMemberFormatter.fullName(ctx)(_.get(assistant, 'member', ''))
            ).join(', ')}
          </div>
        </div>
      )}
    </DIV>
  );
};

export default displayName(View8);
