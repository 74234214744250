import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import B2BCourseRoomModel from '@uz/unitz-models/B2BCourseRoomModel';
import PaginationCalendarModel from '@uz/unitz-models/PaginationCalendarModel';
import CourseRoomFormatter from '@uz/unitz-models/CourseRoomModel/formatter';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import B2BCourseModel from '@uz/unitz-models/B2BCourseModel';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: true }))),

            programs: hook((ctx) => {
              const account_id = ctx.apply('accountModel.getAccountId');
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!account_id) return null;
                    const client = await getClient();
                    const data = await client.request(
                      gql`
                        query GetPrograms($id: uuid!) {
                          b2b_program(where: { account_id: { _eq: $id } }) {
                            id
                            name
                          }
                        }
                      `,
                      { id: account_id }
                    );
                    return data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [account_id]
              );

              return _.get(data, 'b2b_program', {});
            }),

            form: hook((ctx) => {
              const form = useFormik({
                initialValues: {
                  viewBy: '',
                  search: '',
                  status: '',
                  program: '',
                },

                onSubmit: async (values) => {
                  try {
                  } catch (error) {
                    console.log({ error });
                  }
                },

                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                clearFilter: () => {
                  form.setFieldValue('search', '');
                  form.resetForm();
                },

                selectOptions: {
                  viewBy: (() => {
                    return [
                      {
                        value: 'all',
                        label: ctx.apply('i18n.t', 'ZoomToolCalendar.Form.all'),
                      },
                      {
                        value: 'student',
                        label: ctx.apply('i18n.t', 'ZoomToolCalendar.Form.student'),
                      },
                      {
                        value: 'teacher',
                        label: ctx.apply('i18n.t', 'ZoomToolCalendar.Form.teacher'),
                      },
                    ];
                  })(),
                  status: (() => {
                    return [
                      {
                        value: 'all',
                        label: ctx.apply('i18n.t', 'ZoomToolCalendar.Form.all'),
                      },
                      {
                        value: 'InProgress',
                        label: ctx.apply('i18n.t', 'ZoomToolCalendar.Form.inprogress'),
                      },
                    ];
                  })(),
                },
              });

              return form;
            }),

            calendarModel: hook((ctx) => {
              // load event from server
              const ref = React.useRef({});
              _.assign(ref.current, { ctx });
              const userId = ctx.apply('authModel.getUserId');
              const account_id = ctx.apply('accountModel.getAccountId');
              const pagingModel = React.useMemo(() => {
                const windowSize = [31, 'day'];
                // course_room_attendees: { user_id: {_eq: "${userId}"} }
                const condition = `
                  where: {
                    _or: [
                      { course: { course_teacher: { user_id: {_eq: "${userId}"} } } }
                      { course_room_attendees: { user_id: {_eq: "${userId}"} } }
                    ]
                    account_id: {_eq: "${account_id}"}
                    start_at: {_gte: $window_start_at}
                    end_at: {_lt: $window_end_at}
                  }
                  order_by: { start_at: asc_nulls_last }
                `;
                const dataQuery = `
                  b2b_course_room(${condition}) {
                    id
                    name
                    photo_url
                    link_urls
                    materials
                    start_at
                    end_at
                    status
                    duration
                    join_url
                    description
                    course {
                      id
                      name
                      description
                    }
                    course_room_teachers{
                      course_id
                      course_room_id
                      user_id   
                      teacher_type
                      member {
                        id
                        member_profile {
                          first_name
                          last_name
                          email
                          avatar_url
                        }
                      }
                    }
                  }
                `;

                const pagingModel = PaginationCalendarModel.fromConfig({
                  Model: B2BCourseRoomModel,
                  dataQuery,
                  windowSize,
                  subscription: true,
                  itemsPath: 'items',
                  itemsAggPath: 'items_agg.aggregate.count',
                });
                return pagingModel;
              }, [userId]);

              pagingModel.hookState();

              React.useEffect(() => {
                pagingModel.onLoadPageHandler();
              }, [pagingModel]);

              const items = pagingModel.useState('items');
              // console.log('user course room', items);
              return {
                events: CourseRoomFormatter.formatRoomEvents(ctx)(items),
                isLoading: pagingModel.useState('isLoading'),
                handlers: {
                  onNavigate: (...args) => pagingModel.onNavigate(...args),
                },
              };
            }),

            coursePagingModel: hook((ctx) => {
              const ref = React.useRef({});
              _.assign(ref.current, { ctx });
              const userId = ctx.apply('authModel.getUserId');
              // const userId = 'kAa2BcMQbnamtgjNHDUlMvjMLa72';
              const pagingModel = React.useMemo(() => {
                const condition = `
                  where: {
                    account: { members: { user_id: {_eq: "${userId}"}}}
                  }
                  order_by: { updated_at: asc_nulls_last }
                `;
                const dataQuery = `
                  b2b_course(${condition}) {
                    id
                    status
                    start_at
                    name
                  }
                `;

                const pagingModel = PaginationModel.fromConfig({
                  Model: B2BCourseModel,
                  dataQuery,
                  aggQuery: `
                    b2b_course_aggregate(${condition}) {
                      aggregate {
                        count
                      }
                    }
                  `,
                  pageSize: 120,
                });
                return pagingModel;
              }, []);

              pagingModel.hookState();

              React.useEffect(() => {
                pagingModel.onLoadPageHandler(0);
              }, [pagingModel]);

              const coursePagingModel = {
                items: pagingModel.useState('items'),
                page: pagingModel.useState('page'),
                isLoadMore: pagingModel.useState('isLoadMore'),
                isLoading: pagingModel.useState('isLoading'),
                hasPreviousPage: pagingModel.useState('hasPreviousPage'),
                hasNextPage: pagingModel.useState('hasNextPage'),
                hasLoadMore: pagingModel.useState('hasLoadMore'),
              };
              // console.log('user courses:', coursePagingModel.items);
              return coursePagingModel;
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
});

export default bindData;
