import React from 'react';

import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';

const groupHeight = 80;

// TODO: use gstyles
export const CalendarStyle = styled.div`
  .rbc-toolbar {
    row-gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .rbc-toolbar-label {
    color: black;
    font-size: 1rem;
    font-weight: 600;
    ::first-letter {
      text-transform: capitalize;
    }
  }
  .rbc-toolbar .rbc-btn-group:first-of-type {
    color: #577590;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    border-radius: 0.5rem;
    button {
      color: #577590;
      font-size: 14px;
      font-weight: 600;
      padding: 0.5rem 0.75rem;
      :first-child:not(:last-child) {
        margin-right: 1rem;
        border-radius: 0.5rem;
        border-color: ${gstyles.colors.border};
      }
      :nth-child(2) {
        padding: 0.4rem 0.5rem;
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border-color: ${gstyles.colors.border};
      }
      :last-child:not(:first-child) {
        padding: 0.4rem 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border-color: ${gstyles.colors.border};
      }
    }
  }
  .rbc-toolbar {
    padding: 0 12px;
  }
  .rbc-toolbar .rbc-btn-group:last-of-type {
    display: flex;
    background-color: #e6ebf0;
    border-radius: 0.5rem;

    button {
      width: 4rem;
      color: #577590;
      font-size: 14px;
      line-height: 20px;
      padding: 0.25rem 0.5rem;
      border-radius: 0.5rem;
      border-style: none;
      margin: 0.25rem;
    }
    button:active,
    button.rbc-active,
    button:active:hover,
    button:active:focus,
    button.rbc-active:hover,
    button.rbc-active:focus {
      color: #263340;
      font-weight: 600;
      background-color: white;
      border: hidden;
      box-shadow: none;
    }
  }
  .rbc-time-column .rbc-timeslot-group {
    min-height: ${groupHeight}px;
  }
  .rbc-day-slot .rbc-event,
  .rbc-day-slot .rbc-background-event {
    border: none;
    flex-direction: row;
    align-items: center;
  }

  ${'' /* .rbc-month-view {
    min-height: 100vh;
  } */}

  .rbc-month-view {
    height: 100%;
    border-color: ${gstyles.colors.border};
    border-radius: 8px;
    .rbc-month-header {
      .rbc-header {
        padding: 12px;
        text-align: left;
        border-color: ${gstyles.colors.border};
        span {
          color: ${gstyles.colors.sub};
          font-size: 16px;
          text-transform: uppercase;
        }
      }
    }
    .rbc-month-row {
      & + .rbc-month-row {
        border-color: ${gstyles.colors.border};
      }
      .rbc-row-bg {
        .rbc-day-bg {
          & + .rbc-day-bg {
            border-left-color: ${gstyles.colors.border};
          }
          &.rbc-today {
            background: transparent;
          }
        }
      }
      .rbc-row-content {
        text-align: left;

        .rbc-row {
          .rbc-date-cell {
            text-align: left;
            padding: 10px 0 10px 12px;
            font-size: 20px;
            font-weight: bold;
            color: ${gstyles.colors.sub};
            &.rbc-now.rbc-current {
              a {
                border-radius: 50%;
                padding: 3px;
                color: ${gstyles.colors.white500};
                background-color: ${gstyles.colors.brandb500};
              }
            }
          }
        }
      }
    }
  }
  .rbc-time-view {
    height: 100%;
    flex: 1 1 0;
    border-left: none;
    border-top: none;
    .rbc-time-header {
      margin-right: 3px !important;
      .rbc-header {
        padding: 6px 0;
        &.rbc-today {
          background: transparent;
        }
        & + .rbc-header {
          border-left-color: ${gstyles.colors.border};
        }
      }
    }
  }

  .rbc-allday-cell {
    display: none;
  }

  .rbc-time-view .rbc-header {
    border-bottom: none;
  }
  .rbc-calendar {
    overflow-x: hidden;
  }
  .rbc-current-time-indicator {
    display: none;
  }
  height: 100%;
  width: 100%;
`;

export default CalendarStyle;
