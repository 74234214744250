import React from 'react';

import { Views } from 'react-big-calendar';
import { ctx } from '@vl/redata';
import { Popover } from 'antd';
import _ from 'lodash';
import EventDetailsCard from '@uz/unitz-tool-components/ZoomToolCalendarComponent/EventDetailsCard';

export const CustomEventWrapper = (eventWrapperProps) => {
  const event = eventWrapperProps.event;
  const [clickState, $clickState] = React.useState();

  const clickContent = () => {
    return <EventDetailsCard event={event} onCloseHandler={hide} />;
  };
  const handleClickChange = (visible) => {
    $clickState(visible);
  };
  const hide = () => {
    $clickState(false);
  };
  const containerRef = React.useRef();

  return (
    <div {..._.pick(eventWrapperProps, ['onClick', 'onDoubleClick', 'onKeyPress', 'style'])} ref={containerRef}>
      <Popover
        placement="bottomLeft"
        content={clickContent}
        // title={clickContent}
        trigger="click"
        visible={clickState}
        onVisibleChange={handleClickChange}
        getPopupContainer={() => {
          if (
            containerRef.current &&
            (ctx.get('coursesFilter.currentView') === Views.DAY || ctx.get('coursesFilter.currentView') === Views.WEEK)
          ) {
            return containerRef.current;
          }
          return document.body;
        }}
      >
        {eventWrapperProps.children}
      </Popover>
    </div>
  );
};

export default CustomEventWrapper;
