import moment from 'moment';
import cx from 'classnames';
import _ from 'lodash';

export function eventStyleGetter(event, start, end, isSelected) {
  let className = cx('flex-1 items-center content-center rounded-md', {
    'bg-brandb-lightest': moment().isAfter(end),
    'bg-yellow-lightest': moment().isBetween(start, end),
    'bg-blue-lightest': moment().isBefore(start),
  });

  let style = {};
  return {
    className,
    style,
  };
}

export default eventStyleGetter;
