import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import moment from 'moment';
import _ from 'lodash';
import { Views, momentLocalizer } from 'react-big-calendar';
import gstyles from '@vl/gstyles';

const bindData = bindings({
  componentContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: true }))),

            toolbarMessage: hook((ctx) => {
              const messages = {
                date: ctx.apply('i18n.t', 'Calendar.Date'),
                time: ctx.apply('i18n.t', 'Calendar.Time'),
                event: ctx.apply('i18n.t', 'Calendar.Event'),
                allDay: ctx.apply('i18n.t', 'Calendar.Allday'),
                week: ctx.apply('i18n.t', 'Calendar._Week'),
                work_week: 'Work Week',
                day: ctx.apply('i18n.t', 'Calendar.Day'),
                month: ctx.apply('i18n.t', 'Calendar._Month'),
                previous: gstyles.icons({ name: 'arrow-left', size: 18, fill: gstyles.colors.sub }),
                next: gstyles.icons({ name: 'arrow-right', size: 18, fill: gstyles.colors.sub }),
                yesterday: ctx.apply('i18n.t', 'Calendar.Yesterday'),
                tomorrow: ctx.apply('i18n.t', 'Calendar.Tomorrow'),
                today: ctx.apply('i18n.t', 'Calendar.Today'),
                agenda: ctx.apply('i18n.t', 'Calendar.Agenda'),
                noEventsInRange: ctx.apply('i18n.t', 'Calendar.noEventsInRange'),
                showMore: (total) => `+${total} ${ctx.apply('i18n.t', 'Calendar.Event')}`,
              };
              return { messages };
            }),
            coursesFilter: hook((ctx) => {
              const [filterList, $filterList] = React.useState([]);
              const filterEvents = ctx
                .get('calendarModel.events', [])
                .filter((event) => filterList.length === 0 || filterList.includes(event.title));
              const defaultView = Views.DAY;
              const [currentView, $currentView] = React.useState(defaultView);
              const [currentDay, $currentDay] = React.useState(Date.now());
              let initialTime = new Date();

              if (currentView === Views.DAY && !!filterEvents.length) {
                const dayEvents = filterEvents.filter((e) => moment(e.start).isSame(currentDay, 'day'));
                if (dayEvents.length) {
                  initialTime = _.first(dayEvents).start;
                  // console.log('initialTime', moment(initialTime).toDate());
                }
              }
              if (currentView === Views.WEEK && !!filterEvents.length) {
                const date = moment(currentDay)
                  .startOf('week')
                  .toDate();
                const end = moment(currentDay)
                  .endOf('week')
                  .toDate();
                const weekEvents = filterEvents.filter(
                  (event) =>
                    moment(event.start).isSameOrAfter(date, 'day') && moment(event.start).isSameOrBefore(end, 'day')
                );
                if (weekEvents.length) {
                  weekEvents.forEach((event) => {
                    const time = moment(event.start, 'H:mm');
                    const initTime = moment(initialTime, 'H:mm');
                    if (time.hour() * 60 + time.minute() < initTime.hour() * 60 + initTime.minute()) {
                      initialTime = event.start;
                    }
                  });
                }
              }
              return {
                filterSet: (listCourse) => {
                  $filterList(listCourse);
                },
                viewSet: (view) => {
                  // console.log('view change to', view, 'with date as', currentDay);
                  $currentView(view);
                },
                currentView,
                onNavigate: (date) => {
                  if (currentView === Views.DAY || currentView === Views.WEEK) {
                    $currentDay(date);
                  }
                  ctx.apply('calendarModel.handlers.onNavigate', date);
                },
                filterEvents,
                initialTime,
                defaultView,
                // onShowMore: (prop) => {
                //   console.log(prop);
                // },
              };
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
});

export default bindData;
