import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';
import _ from 'lodash';
import cx from 'classnames';
import i18n from 'i18n-js';
import { TIME_HOUR_MINUTE_ML_FORMAT } from '@vl/mod-utils/datetime';
import AvatarGroup from '@uz/unitz-components-web/CalendarComponent/AvatarGroup';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';

const localizer = momentLocalizer(moment);
const timeStep = 30;

const CustomDayEvent = ({ event }) => {
  const textColor = cx('text-white', {
    'text-brandb500': moment().isAfter(event.end),
    'text-yellow500': moment().isBetween(event.start, event.end),
    'text-blue500': moment().isBefore(event.start),
  });
  const numberSlot = (event.end - event.start) / (timeStep * 60 * 1000);
  const timeDuration = `${CourseFormatter.replaceThg()(
    localizer.format(event.start, _.get(TIME_HOUR_MINUTE_ML_FORMAT, i18n.locale, TIME_HOUR_MINUTE_ML_FORMAT.de))
  )} - ${CourseFormatter.replaceThg()(
    localizer.format(event.end, _.get(TIME_HOUR_MINUTE_ML_FORMAT, i18n.locale, TIME_HOUR_MINUTE_ML_FORMAT.de))
  )}`;

  if (numberSlot > 2) {
    return (
      <DIV>
        <div className={cx('h-full mt-2', textColor)}>
          <div className="flex justify-between items-center">
            <div className="font-normal text-main text-xs ">{timeDuration}</div>
            {/* <div className="mr-2 font-normal text-xs text-right">
              <AvatarGroup event={event} />
            </div> */}
          </div>
          <div className={cx('mt-1 font-semibold main text-xs', textColor)}>{event.title}</div>
        </div>
      </DIV>
    );
  }
  return (
    <DIV>
      <div className={cx('flex justify-between items-center h-full', textColor)}>
        <div className="flex flex-col">
          <div className="font-norm al text-main text-xs">{timeDuration}</div>
          <div className={cx('font-semibold text-main text-xs', textColor)}>{event.title}</div>
        </div>
        {/* <div className="mr-2 font-normal text-xs text-right">
          <AvatarGroup event={event} />
        </div> */}
      </div>
    </DIV>
  );
};

export default CustomDayEvent;
