import React from 'react';

import styled from 'styled-components';

export const CustomSelect = styled.div`
  .ant-select {
    &:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 8px;
    }
    .ant-select-selector {
      ${'' /* height: 52px; */}
      border-radius: 0.5rem;
      .ant-select-selection-overflow {
        flex-wrap: nowrap;
        overflow: hidden;
        .ant-select-selection-overflow-item {
          .ant-select-selection-search {
            .ant-select-selection-search-input {
            }
          }
        }
      }
    }
  }
`;

export default CustomSelect;
